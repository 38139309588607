.cards-list
  list-style: none
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: auto
  margin: auto
  flex-direction: row
  &.reverse
    flex-direction: row-reverse
  &-item
    height: 14vw
    min-width: 9vw
    margin: 5px
    &.disabled
      pointer-events: none
      opacity: 0.5
    & .flip-card
      width: 100%
      height: 100%
      perspective: 1000px

    & .flip-card-inner
      position: relative
      width: 100%
      height: 100%
      transition: transform 0.6s
      transform-style: preserve-3d

    & .flip-card.active .flip-card-inner
      transform: rotateY(180deg)

    & .flip-card-front, .flip-card-back
      position: absolute
      width: 100%
      height: 100%
      -webkit-backface-visibility: hidden
      backface-visibility: hidden

    & .flip-card-front
      & img
        width: 100%
        height: 100%
        object-fit: cover
    & .flip-card
      &.animate
        & img
          animation-name: open-card
          animation-duration: .5s
          animation-iteration-count: infinite


    & .flip-card-back
      transform: rotateY(180deg)
      & .cardIcon
        height: 92%
        font-size: 14vw
        &:before
          transform: translate(-55%, -55%)

@keyframes open-card
  from 
    filter: invert(1)
  to 
    filter: none
